import { loadAPI } from 'common/helpers/api';
import React, { useEffect, useState } from 'react';

const useModuleConfig = ({ formKey }) => {
  const [formConfig, setFormConfig] = useState({});
  const [moduleConfig, setModuleConfig] = useState({});
  const [userData, setUserData] = useState({});

  const getUserModuleConfig = async () => {
    const { data } = await loadAPI(`/user/meta/`);
    setUserData(data);
     setModuleConfig(JSON.parse(data?.moduleConfig));

    if (formKey) setFormConfig(JSON.parse(data?.moduleConfig).formConfig[formKey]);

    console.log({ mdouleCOing: JSON.parse(data?.moduleConfig) });
  };

  useEffect(() => {
    getUserModuleConfig();
  }, []);

  return {
    formConfig,
    moduleConfig,
    getUserModuleConfig,
    userData,
  };
};

export default useModuleConfig;
